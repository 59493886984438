import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { PostUserDto, UserDto } from 'src/app/modules/login/services/user-api.service';
import { AuthType } from 'src/app/modules/login/auth-green.service';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Auth Complete': props<{ res: AuthenticationResult }>(),
    'Stop authorizing': emptyProps(),
    'Set Auth Account': props<{ res: AccountInfo }>(),
    'Set Green Auth User': props<{ user: any }>(),
    'Set Auth Type': props<{ authType: AuthType }>(),
    'Set Auth Code': props<{ authCode: string }>(),
    'Set License Expired': props<{ expired: boolean }>(),
    'Set Loginning': props<{ loginning: boolean }>(),
    'Set Only Email Auth Option': props<{ onlyEmail: boolean }>(),

    'Set Clause Name To Be Filtered': props<{ name: string | null }>(),
    'Clause Name Checked Success': emptyProps(),
  },
});

export const GetUserActions = createActionGroup({
  source: 'GetUser',
  events: {
    Login: emptyProps(),
    'Get User Details': emptyProps(),
    'Get User Details Success': props<{ res: UserDto }>(),
    'Get User Details Failure': props<{ error: HttpErrorResponse }>(),
    'Save User Details': props<{ user: PostUserDto }>(),
    'Save User Details Success': props<{ user: UserDto }>(),
    'Save User Details Failure': props<{ error: HttpErrorResponse }>(),
    'Invite User': emptyProps(),
    'Invite User Success': props<{ res: UserDto }>(),
    'Invite User Failure': props<{ error: HttpErrorResponse }>(),
    'Set Inviting': props<{ inviting: boolean }>(),
  },
});

export const logoutAction = createAction('Auth Logout');

export const loggedInSuccessAction = createAction('Auth Logged In Success');
